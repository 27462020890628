import React from "react";

//https://api.chucknorris.io/jokes/random

class ChuckNorris extends React.Component {
  constructor() {
    super();
    this.state = {
      joke: "",
      trivia: "",
    };
  }

  componentDidMount() {
    // fetch("https://api.chucknorris.io/jokes/random")
    //   .then((response) => response.json())
    //   .then((value) => this.setState({ joke: value }));

    fetch("http://numbersapi.com/random/trivia?json")
      .then((response) => response.json())
      .then((text) => this.setState({ trivia: text }));
  }

  render() {
    const { joke, trivia } = this.state;

    return (
      <div>
        {/* <h1 className="pa4 f2 fl w-40 helvetica orange">
          Chuck Norris' Fun Fact Finder
        </h1>
        <p className="pa4 f3 fl w-60 helvetica white">{joke.value}</p> */}

        <h1 className="f2 pa5 fl w-100 tl helvetica blue">
          Did you know that
        </h1>
        <h2 className="f1 pa5 fl w-100 tr helvetica black">{trivia.text}</h2>
      </div>
    );
  }
}

export default ChuckNorris;
